import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import shelfDetails from './shelfDetails';
export default {
  name: '',
  components: {
    shelfDetails
  },
  props: ['rackRowData'],
  watch: {
    currentPage: function() {
      this.getLocDetailsById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLocDetailsById();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      locCode: null,
      locName: null,
      status: {
        value: null,
        text: null
      },
      freeze: {
        value: null,
        text: null
      },
      location: {
        value: null,
        text: null
      },
      rackData: [
        {
          locator_name: null,
          locator_code: null,
          status: 'ACTIVE',
          parent_locator_id: null,
          fa_location_id: null,
          locator_id: 0,
          freeze_status: 'UNFREEZE'
        }
      ],
      rackFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'addShelf',
          class: 'shelf-col'
        },
        {
          key: 'locator_name',
          label: 'Rack Name'
        },
        {
          key: 'locator_code',
          label: 'Rack Code'
        },
        {
          key: 'status'
        },
        {
          key: 'freeze_status'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      showValueSetModal: false,
      payload: null,
      editMode: false,
      showShelfModal: false,
      shelfRowData: null,
      accessButton: false,
      faLocId:null,
    };
  },
  mounted() {
    if (this.$store.state.shared.accessRights) {
      const buttonRights = this.$store.state.shared.accessRights;
      if (buttonRights.edit_allow && buttonRights.add_allow) {
        this.accessButton = true;
      } else {
        this.accessButton = false;
      }
    }
    if (this.rackRowData) {
      this.fillHdrRecordFromAssetMasterLoc(this.rackRowData);
    } else {
      this.editMode = true;
    }
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    // if (mutation.type === 'shared/setActionName') {
    // const actionName = state.shared.actionName;
    // if (actionName === 'update') {
    //   this.editMode = true;
    // }
    // if (actionName === 'save' && this.editMode) {
    //   this.addEditRackDetails();
    // }
    // }
    // });
  },
  methods: {
    editRackModal() {
      this.editMode = true;
    },
    fillHdrRecordFromAssetMasterLoc(item) {
      this.location.text = item.fa_location_name;
      this.location.value = item.fa_location_name;
      this.locName = item.locator_name;
      this.locCode = item.locator_code;
      this.status.text = item.status;
      this.status.value = item.status;
      this.getLocDetailsById();
    },
    getLocDetailsById() {
      const payload = {
        pathVariable:{ 
          faLocId: this.rackRowData.fa_location_id,  
          },
        params:{
          parent_locator_id: this.rackRowData.locator_id?this.rackRowData.locator_id:null
        }
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getLocDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            if (result.length) {
              this.rackData = result;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditRackDetails() {
      const payload = this.rackData.map(item => {
        return {
          fa_location_id: this.rackRowData.fa_location_id,
          // ? item.fa_location_id
          // : this.rackRowData.locator_id,
          locator_code: item.locator_code,
          locator_id: item.locator_id,
          locator_name: item.locator_name,
          parent_locator_id: null,
          status: item.status,
          freeze_status: item.freeze_status
        };
      });
      this.loader = true;
      this.$store
        .dispatch('assets/addLocator', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.rackRowData.locator_id =
              response.data.data[0].parent_locator_id;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.editMode = false;
            this.getLocDetailsById();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.showAlert = true;
          this.loader = false;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    // rowSelected(item) {
    //   this.shelfRowData = item;
    //   this.showHideShelfModal(true);
    // },
    showHideShelfModal(flag, item) {
      this.faLocId=this.rackRowData.fa_location_id
      this.shelfRowData = item;
      this.showShelfModal = flag;
    },
    addNewRow() {
      if (this.editMode) {
        this.rackData.push({
          freeze_status: 'UNFREEZE',
          status: 'ACTIVE',
          locator_name: null,
          locator_code: null,
          parent_locator_id: this.rackRowData.locator_id,
          fa_location_id: this.rackRowData.fa_location_id,
          locator_id: 0
        });
      }
    },
    removeRow(index) {
      this.rackData.splice(index, 1);
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.locIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.STATUS_VSET) {
        this.rackData[this.locIndex].status = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FREEZE_STATUS_VSET) {
        this.rackData[this.locIndex].freeze_status = item.value_code;
      }
      this.rackData = JSON.parse(JSON.stringify(this.rackData));
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValuesOnGrid(vsetCode, index) {
      if (vsetCode === 'STATUS_VSET') {
        this.rackData[index].status = null;
        this.rackData[index].status = null;
      } else if (vsetCode === 'FREEZE_STATUS_VSET') {
        this.rackData[index].freeze_status = null;
        this.rackData[index].freeze_status = null;
      }
    }
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
};